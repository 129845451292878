import { FastField } from "formik"
import React from "react"

import * as S from "./styled"

export const InvisibleDropdown = ({ menuIsOpen = false, ...props }) => {
  return (
    <FastField>
      {({ field, form }) => {
        const selectedOption = props.options
          ? props.options.find(option => option.value === field.value)
          : ""

        const onSelectChange = e => {
          props.addAction(e.id)
          return form.setFieldValue(field.name, e.value)
        }

        return (
          <S.StyledInvisibleDropdown
            {...props}
            {...field}
            isSearchable={false}
            value={selectedOption || ""}
            onChange={onSelectChange}
            menuIsOpen={menuIsOpen}
          />
        )
      }}
    </FastField>
  )
}
