import { theme } from "shared/styles/theme"

export const getSizeOverrides = size => {
  switch (size) {
    case "sm":
      return {
        padding: "1rem",
      }
    case "lg":
      return {
        padding: "1.25rem",
      }
    default:
      return {}
  }
}

export const getBoxShadowOverrides = isPrintMode => {
  return isPrintMode
    ? { border: `1px solid ${theme.color.paleGrey}` }
    : { boxShadow: theme.elevation.shadow100 }
}

export const getTitleOverrides = size => {
  switch (size) {
    case "sm":
      return {
        ...theme.typography.sourceSansPro.font260,
      }
    case "lg":
      return {
        ...theme.typography.sourceSansPro.font360,
      }
    default:
      return {}
  }
}

export const getDotOverrides = (size, dotColor) => {
  const dotStyles = {
    width: size === "lg" ? "0.625rem" : "0.75rem",
    height: size === "lg" ? "0.625rem" : "0.75rem",
  }
  const styles = {
    paddingLeft: "1.25rem",
    position: "relative",
  }

  switch (dotColor) {
    case "yellow":
      return {
        ...styles,
        "& span": { ...dotStyles, backgroundColor: theme.color.peach },
      }
    case "pink":
    case "mistyRose":
    case "lightGoldenrodYellow":
    case "lightBlue":
    case "lightPink":
    case "paleTurquoise":
    case "lightGreen":
    case "lavender":
      return {
        ...styles,
        "& span": { ...dotStyles, backgroundColor: theme.color[dotColor] },
      }
    default:
      return {}
  }
}
