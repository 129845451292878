export const theme = {
  color: {
    black: "#0b091b",
    totalBlack: "#000000",
    royalBlue: "#6992f3",
    linkWater: "#f8fafd",
    darkGray: "#2f324a",
    darkGreyBlue: "#363954",
    lightestGray: "#ebedf0",
    lightGrey: "#cfd4da",
    midGray: "#919da9",
    gray: "#5f656b",
    slateGray: "#5f656b",
    white: "#ffffff",
    whiteSmoke: "#f4f6f7",
    paleGrey: "#f2f4f6",
    avatarGray: "#d4d4d4",
    peach: "#ffd88e",
    lightGoldenrodYellow: "#f4f2d1",
    pink: "#f2cbdb",
    pink2: "#f4c5c5",
    lightPink: "#f6efee",
    mistyRose: "#f5d1f0",
    lightBlue: "#d1e9f4",
    skyBlue: "#759cf5",
    paleTurquoise: "#c1f1f4",
    lavender: "#cbccf2",
    lightGreen: "#c5f4c1",
    lime: "#c2f1be",
    red: "#ff001a",
    reddish: "#d92e2e",
    negativeTag: "#f7497b",
    positiveTag: "#58d274",
    paleSalmon: "#f7c89b",
  },
  boxShadow: {
    lightShadow: "1px 1px 5px 0px rgba(0,0,0,0.1)",
    largeButtonShadow: "0 .8rem 2.1rem 0 rgba(117, 156, 245, 0.3)",
    smallButtonShadow: "0 2px 4px 0 rgba(11, 9, 41, 0.05)",
    dropdownShadow: "0 3.4rem 3.9rem 0 rgba(16, 15, 35, 0.05)",
  },
  animation: {
    fast: "160ms cubic-bezier(0.2, 0.8, 0.4, 1)",
    slow: "460ms cubic-bezier(0.2, 0.8, 0.4, 1)",
  },
  font: {
    family: {
      robotoRegular: "Roboto-Regular, sans-serif",
      robotoMedium: "Roboto-Medium, sans-serif",
      sourceSansProSemibold: "SourceSansPro-Semibold, sans-serif",
      sourceSansProRegular: "SourceSansPro-Regular, sans-serif",
      openSans: "OpenSans-Regular, sans-serif",
      latoBold: "Lato-Bold, sans-serif",
      latoSemibold: "Lato-Semibold, sans-serif",
      latoRegular: "Lato-Regular, sans-serif",
      ttCommonsRegular: "TTCommons-Regular, sans-serif",
    },
    size: {
      xxs: "0.64rem",
      xs: "0.8rem", // 13px
      sm: "0.875rem", // 14px
      base: "1rem",
      md: "1.25rem",
      lg: "1.5rem", // 24px
      xl: "2.125rem", // 34px
      xxl: "2.441rem",
      xxxl: "3.052rem",
      xxxxl: "3.8rem",
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 700,
    },
  },
  size: {
    contentWidth: "90rem",
    contentHeight: "68.75rem",
  },
  border: {
    radius: ".6rem",
  },
  breakpoints: {
    md: 960,
    lg: 1200,
    xl: 1440,
  },
  elevation: {
    shadow000: "none",
    shadow100: "0 2px 4px 0 rgba(16, 15, 35, 0.04)",
    shadow150: "0 2px 4px 0 rgba(16, 15, 35, 0.1)",
    shadow200: "0 18px 39px 0 rgba(16, 15, 35, 0.17)",
    shadow300: "0 54px 63px 0 rgba(16, 15, 35, 0.05)",
  },
  typography: {
    ttCommons: {
      font140: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      font160: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      font240: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1rem",
        fontWeight: 400,
      },
      font260: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1rem",
        fontWeight: 600,
      },
      font340: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1.125rem",
        fontWeight: 400,
      },
      font360: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1.125rem",
        fontWeight: 600,
      },
      font440: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      font460: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      font540: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "3rem",
        fontWeight: 400,
      },
      font560: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "3rem",
        fontWeight: 600,
      },
      font640: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "3.625rem",
        fontWeight: 400,
      },
      font660: {
        fontFamily: "TTCommons, sans-serif",
        fontSize: "3.625rem",
        fontWeight: 600,
      },
    },
    sourceSansPro: {
      font140: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "0.8125rem",
        fontWeight: 400,
      },
      font160: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "0.8125rem",
        fontWeight: 600,
      },
      font240: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      font260: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      font340: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1rem",
        fontWeight: 400,
      },
      font360: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1rem",
        fontWeight: 600,
      },
      font440: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1.125rem",
        fontWeight: 400,
      },
      font460: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1.125rem",
        fontWeight: 600,
      },
      font540: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      font560: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      font640: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "2.125rem",
        fontWeight: 400,
      },
      font660: {
        fontFamily: "SourceSansPro, sans-serif",
        fontSize: "2.125rem",
        fontWeight: 600,
      },
    },
  },
}
