import styled from "styled-components"
import { Link } from "gatsby"

const basicStyles = ({ styleOverrides }) => ({
  textDecoration: "none",
  display: "block",
  border: "none",
  cursor: "pointer",
  outline: "none",
  position: "relative",
  padding: "0 1.5rem",
  ...styleOverrides,
})

export const StyledButton = styled.button(basicStyles)

export const StyledLink = styled(Link)(basicStyles)
