import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

export const PrintableTextarea = ({ value }) => {
  if (!value) {
    return null
  }

  const valueToHtml = value.replace(/\n/g, "<br/>")

  return (
    <S.TextareaRoot>
      <S.PrintableDiv dangerouslySetInnerHTML={{ __html: valueToHtml }} />
    </S.TextareaRoot>
  )
}

PrintableTextarea.defaultProps = { value: "" }
PrintableTextarea.propTypes = { value: PropTypes.string }
