import React from "react"
import PropTypes from "prop-types"
import PrintableInputRange from "./PrintableInputRange"

import * as S from "./styled"

export const PrintableSlider = ({ name, value, max }) => {
  if (!name || !value) {
    return null
  }

  return (
    <S.SliderRow>
      <S.TitleWrapper>
        <S.Title>{name}</S.Title>
      </S.TitleWrapper>
      <S.SliderWrapper>
        <PrintableInputRange percentage={(value / max) * 100} />
      </S.SliderWrapper>
    </S.SliderRow>
  )
}

PrintableSlider.defaultProps = { name: "", value: 125, max: 250 }
PrintableSlider.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number,
}
