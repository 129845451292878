import React from "react"

import * as C from "./components"

export const Slider = ({ value, isPrintMode, ...rest }) => {
  if (isPrintMode) {
    return <C.PrintableSlider {...rest} value={value} />
  }

  return <C.FormSlider {...rest} />
}
