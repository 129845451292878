import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"
import * as H from "./helpers"

export const Button = ({
  children,
  to,
  icon,
  color,
  fontFamily,
  size,
  elevation,
  elevationStep,
  overrides,
  id,
  ...props
}) => {
  /* Prepare style overrides based on passed props. */
  const styleOverrides = {
    ...H.getColorOverrides(color),
    ...H.getSizeOverrides(size),
    ...H.getFontOverrides(size, fontFamily),
    ...H.getElevationOverrides(elevation, elevationStep),
    ...overrides,
  }

  /* Check if this should be a `Link` component. */
  if (to) {
    return (
      <S.StyledLink id={id} styleOverrides={styleOverrides} to={to}>
        {children}
      </S.StyledLink>
    )
  }

  /* Return regular button component. */
  return (
    <S.StyledButton id={id} styleOverrides={styleOverrides} {...props}>
      {children}
    </S.StyledButton>
  )
}

Button.defaultProps = {
  color: "white",
  size: "lg",
  fontFamily: "sourceSansPro",
  to: null,
  elevation: 0,
  elevationStep: 2,
  overrides: {},
  id: "",
}
Button.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "darkGreyBlue",
    "lime",
    "royalBlue",
    "grey",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  fontFamily: PropTypes.oneOf(["sourceSansPro", "ttCommons"]),
  to: PropTypes.string,
  elevation: PropTypes.number,
  elevationStep: PropTypes.number,
  overrides: PropTypes.shape({}),
  id: PropTypes.string,
}
