import React from "react"

import * as C from "./components"

export const Textarea = ({ value, isPrintMode, ...rest }) => {
  if (isPrintMode) {
    return <C.PrintableTextarea {...rest} value={value} />
  }

  return <C.FormTextarea {...rest} />
}
