import React from "react"

import * as S from "./styled"

export const Logo = ({ vertical = false, covered = false, customId }) => (
  <S.StyledLogoLink
    id={customId || (vertical ? "footer-home-button" : "header-home-button")}
    to="/"
    covered={covered ? "true" : ""}
    aria-label="Home"
  >
    {vertical ? (
      <S.StyledLogoVertical />
    ) : (
      <S.StyledLogoHorizontal covered={covered ? "true" : ""} />
    )}
  </S.StyledLogoLink>
)
