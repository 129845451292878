import styled from "styled-components"

const LINE_HEIGHT = "1.29"

export const TextareaRoot = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
`

export const StyledTextarea = styled.textarea`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.darkGreyBlue};
  line-height: ${LINE_HEIGHT};
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  resize: none;
  overflow: hidden;
  border: none;
  outline: none;

  &:focus::placeholder {
    color: transparent;
    outline: none;
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    ${props => props.theme.typography.sourceSansPro.font240};
    color: ${props => props.theme.color.midGrey};
    line-height: ${LINE_HEIGHT};
  }
`

export const InvisibleDiv = styled.div`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.darkGreyBlue};
  line-height: ${LINE_HEIGHT};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  overflow-wrap: break-word;
  pointer-events: none;
  opacity: 0;
`

export const PrintableDiv = styled.div`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.darkGreyBlue};
  line-height: ${LINE_HEIGHT};
  display: block;
  max-width: 100%;
  overflow-wrap: break-word;
`
