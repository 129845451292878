import React, { useRef } from "react"
import PropTypes from "prop-types"
import { FastField } from "formik"

import * as S from "./styled"

export const FormTextarea = React.memo(({ name, placeholder, maxRows }) => {
  const textareaRef = useRef()
  const invisibleDivRef = useRef()

  return (
    <S.TextareaRoot>
      <FastField name={name}>
        {({ field, form }) => {
          const onChange = e => {
            const { value } = e.target
            const fieldValue = field.value || ""

            /**
             * Parse new value.
             * Space after line-break can break div <-> textarea sync.
             * In case of new edge cases - just add below.
             */
            const newValue = value.replace(/\n /g, "\n")

            /* Update invisible div's height. */
            let testValue = newValue.replace(/\n/g, "<br/>")
            if (testValue.substr(-5) === "<br/>") {
              testValue = `${testValue}a`
            }
            invisibleDivRef.current.innerHTML = testValue

            /* Let's check if user is removing characters. */
            if (newValue.length < field.value.length) {
              return form.setFieldValue(field.name, newValue)
            }

            /*  Check if div is too high. */
            if (
              invisibleDivRef.current.clientHeight >
              textareaRef.current.clientHeight
            ) {
              return form.setFieldValue(field.name, fieldValue)
            }

            return form.setFieldValue(field.name, newValue)
          }

          return (
            <S.StyledTextarea
              ref={textareaRef}
              value={field.value}
              placeholder={placeholder}
              onChange={onChange}
              rows={maxRows}
              onBlur={form.submitForm}
            />
          )
        }}
      </FastField>
      <S.InvisibleDiv ref={invisibleDivRef} />
    </S.TextareaRoot>
  )
})

FormTextarea.defaultProps = {
  placeholder: "",
  maxRows: 8,
}
FormTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxRows: PropTypes.number,
}
