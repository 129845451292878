import React, { useState } from "react"
import PropTypes from "prop-types"

import { theme } from "shared/styles/theme"

import { Button } from "../Button"

import * as S from "./styled"
import * as H from "./helpers"
import { InvisibleDropdown } from "../InvisibleDropdown"

export const Card = ({
  children,
  size,
  dotColor,
  title,
  addAction,
  addOptions,
  removeAction,
  noContentWrapper,
  overrides,
  isPrintMode,
  ...props
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  /* Prepare style overrides based on passed props. */
  const styleOverrides = {
    ...H.getSizeOverrides(size),
    ...H.getBoxShadowOverrides(isPrintMode),
    ...overrides,
  }
  const titleStyleOverrides = {
    ...H.getTitleOverrides(size),
    ...H.getDotOverrides(size, dotColor),
  }

  const showDropdownMenu = event => {
    setIsDropdownOpen(true)
    event.stopPropagation()
    window.addEventListener("click", () => {
      setIsDropdownOpen(false)
    })
  }

  return (
    <S.StyledCard
      styleOverrides={styleOverrides}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      {...props}
    >
      {(title || addAction) && (
        <S.TopWrapper>
          {/* Display card's title. */}
          {title && (
            <S.Title styleOverrides={titleStyleOverrides}>
              <S.Dot />
              {title}
            </S.Title>
          )}

          {/* Check if add button should be a regular button. */}
          {addAction && (
            <Button
              size="sm"
              type="button"
              elevation={0}
              elevationStep={1.5}
              onClick={addOptions ? showDropdownMenu : addAction}
              overrides={{
                position: "relative",
                zIndex: "1",
                margin: "-0.4rem",
                padding: "0 0.875rem",
                ...theme.typography.sourceSansPro.font240,
              }}
            >
              Add
              <S.IconAddWrapper>
                <S.StyledIcon />
              </S.IconAddWrapper>
            </Button>
          )}
          {/* Check if add button should be a dropdown. */}
          {addOptions && !!addOptions.length && isDropdownOpen && (
            <InvisibleDropdown
              options={addOptions}
              menuIsOpen
              addAction={addAction}
            />
          )}
          {/* Check if we should add remove button. */}
          {removeAction && (
            <S.StyledDeleteButton
              onClick={removeAction}
              isVisible={isMouseOver}
            >
              <S.IconDelete />
            </S.StyledDeleteButton>
          )}
        </S.TopWrapper>
      )}
      {noContentWrapper ? (
        children
      ) : (
        <S.ContentWrapper>{children}</S.ContentWrapper>
      )}
    </S.StyledCard>
  )
}

Card.defaultProps = {
  size: "lg",
  dotColor: undefined,
  addAction: undefined,
  addOptions: undefined,
  removeAction: undefined,
  title: "",
  noContentWrapper: false,
  overrides: {},
}
Card.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  dotColor: PropTypes.oneOf([
    "yellow",
    "pink",
    "mistyRose",
    "lightGoldenrodYellow",
    "lightBlue",
    "lightPink",
    "paleTurquoise",
    "lightGreen",
    "lavender",
  ]),
  title: PropTypes.string,
  addAction: PropTypes.func,
  removeAction: PropTypes.func,
  addOptions: PropTypes.arrayOf(PropTypes.shape({})),
  noContentWrapper: PropTypes.bool,
  overrides: PropTypes.shape({}),
}
