import styled from "styled-components"
import { theme } from "shared/styles/theme"

const { breakpoints } = theme

export const ContentWrapper = styled.div`
  max-width: 90rem;
  padding: 0 2.1875rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}px) {
    padding: 0 2rem;
  }
`
