import React from "react"
import styled from "styled-components"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

export const StyledModal = styled(ReactModalAdapter)`
  position: relative;
  z-index: 1000;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background: ${props => props.theme.color.black};
      transition: opacity ${props => props.theme.animation.slow};
    }

    &[class*="--after-open"]:before {
      opacity: 0.3;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    background-color: ${props => props.theme.color.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: ${props => props.theme.border.radius};
    box-shadow: ${props => props.theme.boxShadow.lightShadow};
    overflow: visible;
    outline: none;
    opacity: 0;
    margin-top: 2rem;
    transition: opacity ${props => props.theme.animation.slow},
      margin-top ${props => props.theme.animation.slow};

    &[class*="--after-open"] {
      opacity: 1;
      margin-top: 0;
    }
  }
`
