import styled from "styled-components"

import { theme } from "shared/styles/theme"
import AddIcon from "shared/assets/add-plus-button.svg"

export const StyledCard = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  background: ${theme.color.white};
  border-radius: 0.6rem;
  ${props => props.styleOverrides}
`

export const Title = styled.h3`
  line-height: 1;
  margin: 0;
  cursor: default;
  position: relative;
  z-index: 100;
  ${props => props.styleOverrides}
`

export const Dot = styled.span`
  display: block;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ContentWrapper = styled.div`
  margin-top: 1rem;
`

export const StyledIcon = styled(AddIcon)`
  width: 0.5rem;
  height: 0.5rem;
  fill: ${props => props.theme.color.darkGreyBlue};
  opacity: 0.9;
`

export const IconAddWrapper = styled.div`
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
`

export const StyledDeleteButton = styled.button`
  width: 1.3rem;
  height: 1.3rem;
  margin-top: -0.25rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.theme.elevation.shadow150};
  background: ${props => props.theme.color.white};
  pointer-events: ${({ isVisible }) => (isVisible ? "all" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity ${props => props.theme.animation.fast};
`

export const IconDelete = styled(AddIcon)`
  width: 0.6rem;
  height: 0.6rem;
  fill: ${props => props.theme.color.midGray};
  transform: rotate(45deg);
`
