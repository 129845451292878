import { theme } from "shared/styles/theme"

const { darkGray, darkGreyBlue, royalBlue, white, lime } = theme.color

export const getColorOverrides = type => {
  switch (type) {
    case "white":
      return { backgroundColor: white, color: darkGray }
    case "darkGreyBlue":
      return { backgroundColor: darkGreyBlue, color: white }
    case "lime":
      return { backgroundColor: lime, color: darkGreyBlue }
    case "royalBlue":
      return { backgroundColor: royalBlue, color: white }
    case "grey":
      return { backgroundColor: theme.color.paleGrey, color: theme.color.black }
    default:
      return {}
  }
}

export const getSizeOverrides = size => {
  switch (size) {
    case "sm":
      return {
        height: "1.875rem",
        lineHeight: "1.875rem",
        borderRadius: "0.9375rem",
      }
    case "md":
      return {
        height: "2.4375rem",
        lineHeight: "2.4375rem",
        borderRadius: "1.23rem",
      }
    case "lg":
      return {
        height: "3.125rem",
        lineHeight: "3.125rem",
        borderRadius: "1.56rem",
      }
    case "xl":
      return {
        height: "3.4375rem",
        lineHeight: "3.4375rem",
        borderRadius: "1.72rem",
      }
    default:
      return {}
  }
}

export const getFontOverrides = (size, fontFamily) => {
  if (fontFamily === "ttCommons") {
    switch (size) {
      case "sm":
      case "md":
        return theme.typography.ttCommons.font160 || {}
      case "lg":
      case "xl":
        return theme.typography.ttCommons.font260 || {}
      default:
        return {}
    }
  }

  if (fontFamily === "sourceSansPro") {
    switch (size) {
      case "sm":
      case "md":
        return theme.typography.sourceSansPro.font160 || {}
      case "lg":
      case "xl":
        return theme.typography.sourceSansPro.font260 || {}
      default:
        return {}
    }
  }

  return {}
}

export const getElevationOverrides = (elevation, elevationStep) => {
  let boxShadow
  switch (elevation) {
    case 1:
      boxShadow = theme.elevation.shadow100
      break
    case 2:
      boxShadow = theme.elevation.shadow200
      break
    case 3:
      boxShadow = theme.elevation.shadow300
      break
    default:
      boxShadow = theme.elevation.shadow000
  }
  let hoverBoxShadow
  switch (elevation + elevationStep) {
    case 1:
      hoverBoxShadow = theme.elevation.shadow100
      break
    case 1.5:
      hoverBoxShadow = theme.elevation.shadow150
      break
    case 2:
      hoverBoxShadow = theme.elevation.shadow200
      break
    case 3:
      hoverBoxShadow = theme.elevation.shadow300
      break
    default:
      hoverBoxShadow = theme.elevation.shadow000
  }

  return {
    boxShadow,
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "inherit",
      pointerEvents: "none",
      opacity: 0,
      transition: `opacity ${theme.animation.slow}`,
      boxShadow: hoverBoxShadow,
    },
    "&:hover:before": {
      opacity: 1,
    },
  }
}
