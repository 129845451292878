import React, { useState } from "react"
import PropTypes from "prop-types"
import { FastField } from "formik"

import * as S from "./styled"

export const FormSlider = ({
  title,
  name,
  onRemove,
  placeholder,
  step,
  min,
  max,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  return (
    <S.SliderRow
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <S.TitleWrapper>
        {!title ? (
          <FastField name={`${name}.name`}>
            {({ field, form }) => (
              <S.Input
                {...field}
                type="text"
                placeholder={placeholder}
                data-lpignore="true"
                onBlur={form.submitForm}
              />
            )}
          </FastField>
        ) : (
          <S.Title>{title}</S.Title>
        )}
      </S.TitleWrapper>
      <S.SliderWrapper>
        <FastField name={`${name}.value`}>
          {({ field, form }) => (
            <S.InputRange
              {...field}
              type="range"
              min={min}
              max={max}
              step={step}
              percentage={(field.value / max) * 100}
              isMouseOver={isMouseOver}
              onBlur={form.submitForm}
            />
          )}
        </FastField>
      </S.SliderWrapper>
      {!!onRemove && (
        <S.StyledDeleteButton
          type="button"
          onClick={onRemove}
          isVisible={isMouseOver}
        >
          <S.IconDelete />
        </S.StyledDeleteButton>
      )}
    </S.SliderRow>
  )
}

FormSlider.defaultProps = {
  title: "",
  onRemove: undefined,
  placeholder: undefined,
  step: 1,
  min: 1,
  max: 250,
}
FormSlider.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  onRemove: PropTypes.func,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
}
