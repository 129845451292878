import React from "react"
import PropTypes from "prop-types"

import * as S from "./styled"

export const Modal = ({ isOpen, onRequestClose, children }) => (
  <S.StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
    {children}
  </S.StyledModal>
)

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
}
