import styled from "styled-components"

import { theme } from "shared/styles/theme"
import AddIcon from "shared/assets/add-plus-button.svg"

const INPUT_HEIGHT = "0.45rem"

export const SliderRow = styled.div`
  height: 3.37rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
`

export const TitleWrapper = styled.div`
  width: 100%;
  height: 1.4rem;
  margin-top: 0.4rem;
`

export const Title = styled.p`
  ${theme.typography.sourceSansPro.font260}
  color: ${theme.color.black};
  margin: 0;
  cursor: default;
`

export const SliderWrapper = styled.div`
  position: relative;
  width: calc(100% - 1.9rem);
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RangeWrapper = styled.div`
  height: ${INPUT_HEIGHT};
  width: 100%;
  position: relative;
  border-radius: ${INPUT_HEIGHT};
  background-color: ${props => props.theme.color.paleGrey};
`

export const SliderValue = styled.div`
  height: 100%;
  border-radius: ${INPUT_HEIGHT};
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.percentage}%;
  background-color: ${props => props.theme.color.royalBlue};
`

export const InputRange = styled.input`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: ${INPUT_HEIGHT};
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;
    height: 0.9rem;
    width: 0.9rem;
    opacity: ${({ isMouseOver }) => (isMouseOver ? 1 : 0)};
    transition: opacity ${props => props.theme.animation.fast};
  }

  &::-moz-range-track {
    height: ${INPUT_HEIGHT};
    width: 100%;
    background-color: ${props => props.theme.color.paleGrey};
    border-radius: ${INPUT_HEIGHT};
    z-index: 0;
  }

  &::-moz-range-progress {
    height: ${INPUT_HEIGHT};
    width: 100%;
    background-color: ${props => props.theme.color.royalBlue};
    border-radius: ${INPUT_HEIGHT};
    z-index: 0;
  }

  &:hover::-webkit-slider-thumb {
    opacity: 1;
    border-radius: 1.25rem;
    border: 4px solid ${props => props.theme.color.royalBlue};
    background: rgba(255, 255, 255, 0.8);
    box-shadow: ${props => props.theme.boxShadow.lightShadow};
    cursor: pointer;
    outline: none;
    z-index: 2;
  }

  &::-moz-range-thumb {
    display: block;
    position: relative;
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 1.25rem;
    border: 5px solid ${props => props.theme.color.royalBlue};
    background: rgba(255, 255, 255, 0.8);
    box-shadow: ${props => props.theme.boxShadow.lightShadow};
    cursor: pointer;
    margin-top: 0;
    outline: none;
    z-index: 2;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: ${INPUT_HEIGHT};
    background-color: ${props => props.theme.color.paleGrey};
    border-radius: ${INPUT_HEIGHT};
    z-index: 0;
    pointer-events: none;
  }

  &::after {
    width: ${props => props.percentage}%;
    background-color: ${props => props.theme.color.royalBlue};
  }
`

export const Input = styled.input`
  width: 100%;
  ${theme.typography.sourceSansPro.font260}
  color: ${theme.color.black};
  outline: none;
  border: none;
  background-color: transparent;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.color.black};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::placeholder {
    ${theme.typography.sourceSansPro.font260}
    color: ${theme.color.black};
  }
  &::-moz-placeholder {
    opacity: 1;
  }
`

export const StyledDeleteButton = styled.button`
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: none;
  margin-left: 0.6rem;
  cursor: pointer;
  outline: none;
  box-shadow: ${props => props.theme.elevation.shadow150};
  background: ${props => props.theme.color.white};
  pointer-events: ${({ isVisible }) => (isVisible ? "all" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity ${props => props.theme.animation.fast};
`

export const IconDelete = styled(AddIcon)`
  width: 0.6rem;
  height: 0.6rem;
  fill: ${props => props.theme.color.midGray};
  position: relative;
  transform: rotate(45deg) translate(-10%, -5%);
  vertical-align: middle;
`
