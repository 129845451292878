import React from "react"
import * as S from "./styled"

const PrintableInputRange = ({ percentage }) => {
  return (
    <S.RangeWrapper>
      <S.SliderValue percentage={percentage} />
    </S.RangeWrapper>
  )
}

export default PrintableInputRange
