import styled from "styled-components"
import Select from "react-select"

export const StyledInvisibleDropdown = styled(Select)`
  cursor: pointer;
  position: absolute !important;
  right: 1.25rem;

  & div[class*="-container"] {
    z-index: -1;
  }
  & div[class*="-IndicatorsContainer"] {
    display: none;
    & > div:first-of-type {
      display: none;
    }
  }
  & span[class*="-indicatorSeparator"] {
    display: none;
  }
  & div[class*="-indicatorContainer"] {
    padding: 0;
    & > svg {
      display: none;
    }
  }
  & div[class*="-control"] {
    width: 10rem;
    border-style: none;
    & > div:first-of-type {
      display: none;
    }
  }
  & div[class*="-placeholder"] {
    display: none;
  }
  & div[class*="-singleValue"] {
    display: none;
  }
  & div[class*="-menu"] {
    box-shadow: ${props => props.theme.boxShadow.lightShadow};
    border-radius: 0.4rem;
    min-width: 7.5rem;
    max-width: max-content;
    z-index: 3;
    right: 0;
  }
  & div[class*="-option"] {
    color: ${props => props.theme.color.black};
    ${props => props.theme.typography.sourceSansPro.font240};
    background: white;
    transition: background 0.3s;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.color.lightGrey};
    }
  }
  & div[class*="-Input"] {
    & > div:first-of-type {
      color: transparent; //cursor
    }
  }
`
